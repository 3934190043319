<template>
  <div class="payee" v-loading="loading">
    <div
      class="payeeItem"
      :class="{
        approved: item.stateTag == '1',
        approved2: item.stateTag == '2',
        closed: item.stateTag == '3',
        new: item.stateTag == '4',
      }"
      v-for="item in paayeeList"
      :key="item.id"
    >
      <div class="payeeItemContainer">
        <div class="name">{{ item.invoiceTitle }}</div>
        <div class="GSTNo">
          <div class="GSTNoTitle">{{ $t("lang.SText75") }}</div>
          <div class="GSTNoNum">{{ item.gstNo }}</div>
        </div>
        <div class="address">
          {{ item.address }}
        </div>
        <div class="bankTitle">{{ $t("lang.SText76") }}</div>
        <div class="bankName">{{ item.bankName }}</div>
        <div class="GSTNo">
          <div class="GSTNoTitle">{{ $t("lang.SText77") }}</div>
          <div class="GSTNoNum">{{ item.accountNo }}</div>
        </div>
      </div>
      <div
        class="signOut-icon1"
        :class="{ 'signOut-icon': item.signOut }"
        @mouseover="signOutHover(item)"
        @mouseleave="signOutHover(item, 1)"
      >
        ···
      </div>
      <div
        v-show="item.signOut"
        class="signOutHover"
        @mouseleave="signOutHover(item, 1)"
        @mouseover="signOutHover(item)"
      >
        <div class="list-button">
          <el-button
            class="list-button-item"
            @click="deactivate(item)"
            v-if="item.todo == 'Deactivate'"
            :disabled="item.lockState == '1' ? true : false"
            :class="{ type1: item.lockState == '1' }"
            >{{ $t("lang.SText78") }}</el-button
          >
          <el-button
            class="list-button-item"
            @click="Delete(item)"
            v-else-if="item.todo == 'Delete'"
            :disabled="item.lockState == '1' ? true : false"
            :class="{ type1: item.lockState == '1' }"
            >{{ $t("lang.BText40") }}</el-button
          >
          <el-button
            class="list-button-item"
            @click="Edit(item)"
            :disabled="item.lockState == '1' ? true : false"
            :class="{ type1: item.lockState == '1' }"
            >{{ $t("lang.QText77") }}</el-button
          >
        </div>
      </div>
      <!-- <div class=""></div> -->
      <div
        class="status1"
        v-if="item.payeeState == 20 && item.lockState == '0'"
      >
        {{ $t("lang.SText79") }}
      </div>
      <div
        class="status1"
        v-else-if="item.payeeState == 20 && item.lockState == '1'"
      >
        {{ $t("lang.SText80") }}
      </div>
      <div
        class="status2"
        v-if="item.payeeState == 30 && item.lockState == '0'"
      >
        {{ $t("lang.SText81") }}
      </div>
      <div
        class="status2"
        v-else-if="item.payeeState == 30 && item.lockState == '1'"
      >
        {{ $t("lang.SText80") }}
      </div>
      <div
        class="status3"
        v-if="(item.payeeState == 10) & (item.lockState == '0')"
      >
        {{ $t("lang.SText82") }}
      </div>
      <div
        class="status3"
        v-else-if="(item.payeeState == 10) & (item.lockState == '1')"
      >
        {{ $t("lang.SText80") }}
      </div>
      <div
        class="status3"
        v-if="(item.payeeState == 40) & (item.lockState == '0')"
      >
        {{ $t("lang.SText82") }}
      </div>
      <div
        class="status3"
        v-else-if="(item.payeeState == 40) & (item.lockState == '1')"
      >
        {{ $t("lang.SText80") }}
      </div>
      <!-- <div class="status3" v-if="item.lockState == '0'">NEW</div>
      <div class="status3" v-if="item.lockState == '1'">Locked</div> -->
    </div>

    <div
      class="addNew payeeItem"
      @click="add"
      :class="{ bg: paayeeList.length == 0 }"
    >
      <div class="payeeItemContainer" v-if="paayeeList.length == 0">
        <div class="name">{{ $t("lang.SText83") }}</div>
        <div class="GSTNo">
          <div class="GSTNoTitle">{{ $t("lang.SText75") }}</div>
        </div>
        <div class="address">
          {{ $t("lang.SText84") }}
        </div>
        <div class="bankTitle">{{ $t("lang.SText76") }}</div>
        <div class="bankName">{{ $t("lang.SText85") }}</div>
        <div class="GSTNo">
          <div class="GSTNoTitle">{{ $t("lang.SText77") }}</div>
        </div>
      </div>
      <div
        style="
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 380px;
          height: 200px;
          padding: 20px;
          color: #fff;
          left: 0;
          top: 0;
          color: #299be4;
          z-index: 3;
        "
      >
        <div class="addIcon">+</div>
        <div class="addtext">{{ $t("lang.SText86") }}</div>
      </div>
    </div>

    <el-dialog
      :visible.sync="editFlag"
      width="70%"
      :destroy-on-close="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      class="dw-dialog"
    >
      <div class="dialog-title1">
        <div class="left">
          <svg-icon icon-class="finance" class="svg-icon"></svg-icon>
          {{ $t("lang.SText87") }}
        </div>
        <div class="close" @click="closeDialog()">
          <svg-icon class="close-icon" icon-class="close"></svg-icon>
        </div>
      </div>
      <div class="el-dialog-main">
        <div class="editInfo">
          <el-form :model="form" :rules="rules" ref="addressForm">
            <div class="dw-card2">
              <div class="main" style="padding: 0">
                <el-form-item
                  class="form-item"
                  prop="invoiceTitle"
                  :label="$t('lang.SText88')"
                >
                  <el-input v-model="form.invoiceTitle"></el-input>
                </el-form-item>
                <el-form-item
                  class="form-item"
                  prop="address"
                  :label="$t('lang.SText84')"
                >
                  <el-input v-model="form.address"> </el-input>
                </el-form-item>
                <div class="form-line">
                  <el-form-item
                    class="form-item"
                    prop="countryOrRegion"
                    :label="$t('lang.QText52')"
                  >
                    <el-select
                      class="date-card-select"
                      v-model="form.countryOrRegion"
                      :placeholder="$t('lang.BText80')"
                      @change="countryChange"
                      filterable
                      remote
                      :remote-method="findcountryOrRegionList"
                      :loading="selectLoading"
                    >
                      <el-option
                        v-for="(item, index) in countryList"
                        :key="index"
                        :label="item.name"
                        :value="item.code"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item
                    class="form-item"
                    prop="state"
                    :label="$t('lang.QText53')"
                  >
                    <el-select
                      class="date-card-select"
                      v-model="form.state"
                      :placeholder="$t('lang.BText80')"
                      @change="stateChange"
                      filterable
                      remote
                      :remote-method="findstateList"
                      :loading="selectLoading"
                    >
                      <el-option
                        v-for="(item, index) in stateList"
                        :key="index"
                        :label="item.name"
                        :value="item.code"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
                <div class="form-line">
                  <el-form-item
                    class="form-item"
                    prop="city"
                    :label="$t('lang.QText54')"
                  >
                    <el-select
                      class="date-card-select"
                      v-model="form.city"
                      :placeholder="$t('lang.BText80')"
                      filterable
                      remote
                      :remote-method="findCityList"
                      :loading="selectLoading"
                    >
                      <el-option
                        v-for="(item, index) in cityList"
                        :key="index"
                        :label="item.name"
                        :value="item.code"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item
                    class="form-item"
                    prop="zipCode"
                    :label="$t('lang.QText55')"
                  >
                    <el-input
                      v-model="form.zipCode"
                      type="number"
                      @keydown.native="limite"
                    >
                    </el-input>
                  </el-form-item>
                </div>
                <el-form-item
                  class="form-item"
                  prop="gstNo"
                  :label="$t('lang.SText75')"
                >
                  <el-input v-model="form.gstNo"> </el-input>
                </el-form-item>
                <el-form-item
                  class="form-item"
                  prop="bankName"
                  :label="$t('lang.SText89')"
                >
                  <el-input v-model="form.bankName"> </el-input>
                </el-form-item>
                <el-form-item
                  class="form-item"
                  prop="accountNo"
                  :label="$t('lang.SText77')"
                >
                  <el-input v-model="form.accountNo"> </el-input>
                </el-form-item>
              </div>
            </div>

            <div class="bottom-button">
              <el-button
                type="primary"
                :loading="loadSave"
                @click="Save('addressForm')"
                >{{ $t("lang.SText13") }}</el-button
              >
              <el-button type="info" @click="Cancel">{{
                $t("lang.QText79")
              }}</el-button>
            </div>
          </el-form>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectLoading: false,
      editFlag: false,
      loadSave: false,
      form: {},
      rules: {
        invoiceTitle: [
          { required: false, message: this.$t("lang.SText182") },
          { min: 5, max: 50, message: this.$t("lang.SText172") },
        ],
        gstNo: [
          { required: true, message: this.$t("lang.SText183") },
          { min: 5, max: 50, message: this.$t("lang.SText172") },
        ],
        address: [
          { required: true, message: this.$t("lang.SText180") },
          { min: 5, max: 150, message: this.$t("lang.SText173") },
        ],
        countryOrRegion: [
          {
            required: true,
            message: this.$t("lang.SText161"),
            trigger: "change",
          },
        ],
        state: [
          {
            required: true,
            message: this.$t("lang.SText160"),
            trigger: "change",
          },
        ],
        city: [
          {
            required: true,
            message: this.$t("lang.SText159"),
            trigger: "change",
          },
        ],
        zipCode: [
          { required: false, message: this.$t("lang.SText177") },
          { min: 0, max: 20, message: this.$t("lang.SText181") },
        ],
        bankName: [
          { required: false, message: this.$t("lang.SText184") },
          { min: 5, max: 50, message: this.$t("lang.SText172") },
        ],
        accountNo: [
          { required: true, message: this.$t("lang.SText185") },
          { min: 5, max: 50, message: this.$t("lang.SText172") },
        ],
      },
      countryList: [],
      pageSize: 3,
      pageNo: 1,
      total: 0,
      currentPage: 1,
      paayeeList: [],
      stateList: [],
      cityList: [],
      userinfo: {},
      token: "",
    };
  },
  created() {
    this.$session("countryList").get((value) => {
      this.countryList = value;
    });
    this.$session("userInfo").get((value) => {
      this.userinfo = value;
    });
    this.$session("token").get((value) => {
      this.token = value;
    });
    this.payeeList();
  },
  methods: {
    limite(e) {
      let key = e.key;
      if (key === "e" || key === ".") {
        e.returnValue = false;
        return false;
      }
      return true;
    },
    signOutHover(data, type) {
      if (type) {
        this.$set(data, "signOut", false);
        return;
      }
      this.$set(data, "signOut", true);
    },
    closeDialog() {
      this.editFlag = false;
    },
    Cancel() {
      this.editFlag = false;
    },
    payeeList() {
      let that = this;
      that.loading = true;
      let data = {
        companyId: that.userinfo.companyid,
        // companyId:"1481550332951117824",
        pageSize: that.pageSize,
        pageNo: that.pageNo,
      };
      that.$ajax.cPost(that.source.basCompany_api.payee, data).then((res) => {
        if (res.data.result == "true") {
          that.loading = false;
          that.paayeeList = res.data.list;
          that.total = res.data.allCount;
        } else {
          that.loading = true;
          that.paayeeList = [];
          that.$notify.error({
            title: res.data.message,
            offset: 100,
            duration: 3000,
          });
        }
      });
    },
    add() {
      this.editFlag = true;
      this.$set(this.form, "address", "");
      this.$set(this.form, "city", "");
      this.$set(this.form, "state", "");
      this.$set(this.form, "countryOrRegion", "");
      this.$set(this.form, "zipCode", "");
      this.$set(this.form, "addressId", "");
      this.$set(this.form, "invoiceTitle", "");
      this.$set(this.form, "gstNo", "");
      this.$set(this.form, "bankName", "");
      this.$set(this.form, "accountNo", "");
      this.$set(this.form, "id", "");
      this.$set(this.form, "payeeState", "");
      // 清除表单校验的提示
      if (this.$refs["addressForm"]) {
        // 延时执行
        this.$nextTick(function () {
          this.$refs["addressForm"].clearValidate();
        });
      }
    },
    Save(formName) {
      var that = this;
      that.$refs[formName].validate((valid) => {
        if (valid) {
          that.loadSave = true;
          let data = {
            invoiceAddress: that.form.address ? that.form.address : "",
            city: that.form.city ? that.form.city : "",
            state: that.form.state ? that.form.state : "",
            countryOrRegion: that.form.countryOrRegion
              ? that.form.countryOrRegion
              : "",
            zipCode: that.form.zipCode ? that.form.zipCode : "",
            addressId: that.form.addressId ? that.form.addressId : "",
            invoiceTitle: that.form.invoiceTitle ? that.form.invoiceTitle : "",
            gstNo: that.form.gstNo ? that.form.gstNo : "",
            bankName: that.form.bankName ? that.form.bankName : "",
            accountNo: that.form.accountNo ? that.form.accountNo : "",
            id: that.form.id ? that.form.id : "",
            // companyId:"1481550332951117824",
            companyId: that.userinfo.companyid,
          };
          console.log(data);
          that.$ajax
            .cPost(that.source.basCompany_api.insert, data)
            .then((res) => {
              if (res.data.result) {
                that.editFlag = false;
                that.loadSave = false;
                that.payeeList();
                that.$notify({
                  title: res.data.message,
                  type: "success",
                  offset: 100,
                  duration: 3000,
                });
              } else {
                that.editFlag = true;
                that.loadSave = false;
                that.$notify.error({
                  title: res.data.message,
                  offset: 100,
                  duration: 3000,
                });
              }
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    deactivate(val) {
      let that = this;
      let data = {
        id: val.id,
      };
      that.$ajax.cPost(that.source.basCompany_api.stop, data).then((res) => {
        if (res.data.result) {
          that.payeeList();
          that.$notify({
            title: res.data.message,
            type: "success",
            offset: 100,
            duration: 3000,
          });
        } else {
          that.$notify.error({
            title: res.message,
            offset: 100,
            duration: 3000,
          });
        }
      });
    },
    Delete(val) {
      let that = this;
      let data = {
        id: val.id,
        addressId: val.addressId,
      };
      that.$ajax.cPost(that.source.basCompany_api.delete, data).then((res) => {
        if (res.data.result) {
          that.payeeList();
          that.$notify({
            title: res.data.message,
            type: "success",
            offset: 100,
            duration: 3000,
          });
        } else {
          that.$notify.error({
            title: res.message,
            offset: 100,
            duration: 3000,
          });
        }
      });
    },
    Edit(val) {
      this.editFlag = true;
      this.$set(this.form, "address", val.address);
      this.$set(this.form, "city", val.city);
      this.$set(this.form, "state", val.state);
      this.$set(this.form, "countryOrRegion", val.countryOrRegion);
      this.$set(this.form, "zipCode", val.zipCode);
      this.$set(this.form, "addressId", val.addressId);
      this.$set(this.form, "invoiceTitle", val.invoiceTitle);
      this.$set(this.form, "gstNo", val.gstNo);
      this.$set(this.form, "bankName", val.bankName);
      this.$set(this.form, "accountNo", val.accountNo);
      this.$set(this.form, "id", val.id);
      this.$set(this.form, "payeeState", val.payeeState);
      this.getStateList({ code: val.countryOrRegion });
      this.getCityList({ state: val.state, code: val.countryOrRegion });
      // 清除表单校验的提示
      if (this.$refs["addressForm"]) {
        // 延时执行
        this.$nextTick(function () {
          this.$refs["addressForm"].clearValidate();
        });
      }
    },
    getStateList(data) {
      let that = this;
      that.$ajax
        .cPost(that.source.address_api.getStateList, data)
        .then((res) => {
          if (res.data.result) {
            that.stateList = res.data.data;
          } else {
            that.$notify.error({
              title: res.message,
              offset: 100,
              duration: 3000,
            });
          }
        });
    },
    getCityList(data) {
      let that = this;
      that.$ajax
        .cPost(that.source.address_api.getCityList, data)
        .then((res) => {
          if (res.data.result) {
            that.cityList = res.data.data;
          } else {
            that.$notify.error({
              title: res.message,
              offset: 100,
              duration: 3000,
            });
          }
        });
    },
    countryChange(e) {
      console.log(e);
      this.$set(this.form, "state", "");
      this.$set(this.form, "city", "");
      if (e) {
        this.getStateList({ code: e });
      }
    },
    stateChange(e) {
      console.log(e);
      this.$set(this.form, "state", e);
      this.$set(this.form, "city", "");
      if (e) {
        this.getCityList({ state: e, code: this.form.countryOrRegion });
      }
    },
    findcountryOrRegionList(e) {
      this.selectLoading = true;
      this.$ajax
        .cPost(this.source.address_api.getCountryList, { name: e })
        .then((res) => {
          console.log(res);
          this.selectLoading = false;
          if ((res.data.result = "true")) {
            this.countryList = res.data.data;
          } else {
            this.$notify.error({
              title: res.data.message,
              offset: 100,
              duration: 3000,
            });
          }
        });
    },
    findstateList(e) {
      this.selectLoading = true;
      this.$ajax
        .cPost(this.source.address_api.getStateList, {
          name: e,
          code: this.form.countryOrRegion,
        })
        .then((res) => {
          console.log(res);
          this.selectLoading = false;
          if ((res.data.result = "true")) {
            this.stateList = res.data.data;
          } else {
            this.$notify.error({
              title: res.data.message,
              offset: 100,
              duration: 3000,
            });
          }
        });
    },
    findCityList(e) {
      this.selectLoading = true;
      this.$ajax
        .cPost(this.source.address_api.getCityList, {
          name: e,
          state: this.form.state,
          code: this.form.countryOrRegion,
        })
        .then((res) => {
          console.log(res);
          this.selectLoading = false;
          if ((res.data.result = "true")) {
            this.cityList = res.data.data;
          } else {
            this.$notify.error({
              title: res.data.message,
              offset: 100,
              duration: 3000,
            });
          }
        });
    },
    // getStateList(data) {
    //   let that = this;
    //   that.$ajax
    //     .cPost(that.source.address_api.getStateList, data)
    //     .then((res) => {
    //       if (res.data.result) {
    //         that.stateList = res.data.data;
    //       } else {
    //         that.$notify.error({
    //           title: res.message,
    //           offset: 100,
    //           duration: 3000,
    //         });
    //       }
    //     });
    // },
    // getCityList(data) {
    //   let that = this;
    //   that.$ajax
    //     .cPost(that.source.address_api.getCityList, data)
    //     .then((res) => {
    //       if (res.data.result) {
    //         that.cityList = res.data.data;
    //       } else {
    //         that.$notify.error({
    //           title: res.message,
    //           offset: 100,
    //           duration: 3000,
    //         });
    //       }
    //     });
    // },
    // countryChange(e) {
    //   console.log(e);
    //   this.$set(this.form,'state',"")
    //   if (e) {
    //     this.getStateList({ code: e });
    //   }
    // },
    // stateChange(e) {
    //   console.log(e);
    //   this.$set(this.form,'state',e)
    //   this.$set(this.form,'city',"")
    //   if (e) {
    //     this.getCityList({ state: e, code: this.form.countryOrRegion });
    //   }
    // }
  },
};
</script>

<style lang="scss">
@import "../../../assets/base.scss";
.payee {
  width: 900px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  text-align: left;
  padding-bottom: 50px;
  margin-left: 150px;
  .form-line .el-form-item {
    width: 50%;
    float: left;
    .el-form-item__label,
    .el-select {
      width: 98%;
    }
  }
  .el-dialog {
    margin-top: 0 !important;
    min-height: 100%;
    margin: 0 auto;
    .el-dialog__header {
      padding: 0;
      display: none;
    }

    .el-dialog__body {
      padding: 0;
      min-height: 100%;
      // overflow: auto;
      .editInfo {
        width: 770px;
        // margin: auto;
        padding: 50px 0;
        background: #fff;
        .el-input__inner {
          height: 48px;
        }
        .form-line {
          display: flex;
          justify-content: space-between;
          .form-item {
            width: 48%;
            position: relative;
          }
        }
        .el-form-item {
          display: flex;
          flex-direction: column;
        }
        .bottom-button {
          margin: auto;
          width: 400px;
          margin-bottom: 25px;
          .el-button {
            width: 180px;
            height: 64px;
          }
        }
        .step1-select {
          width: 100px;
        }
        .phoneInput {
          width: 240px;
        }
        .tagList {
          width: 500px;
        }
      }
     
    }
  }
  .approved {
    background-color: $mainColor;
  }
  .closed {
    background-color: $fontColor1;
  }
  .new {
    background-color: $fontColor2;
  }
  .approved2 {
    background-color: $background2;
  }
  .addNew {
    cursor: pointer;
    width: 416px;
    height: 236px;
    border: 2px dashed $background2;
    color: $background2;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    .addIcon {
      font-size: 50px;
      margin-right: 40px;
    }
    .addtext {
      font-size: 30px;
    }
  }
  .addNew:hover {
    background-color: $background;
    color: #fff;
    border: 2px solid $background;
  }
  .bg {
    background: #efefef;
  }
  .payeeItem {
    width: 380px;
    height: 200px;
    padding: 20px;
    position: relative;
    color: #fff;
    margin-bottom: 20px;
    .payeeItemContainer {
      .name {
        font-size: 20px;
      }
      .GSTNo {
        margin: 10px 0;
        display: flex;
        color: #e6e6e6;
        .GSTNoTitle {
          font-size: 12px;
          margin-right: 10px;
        }
        .GSTNoNum {
          font-size: 14px;
          color: $background;
        }
      }
      .address {
        font-size: 14px;
        margin: 20px 0;
      }
      .bankTitle {
        border-top: 1px solid #fff;
        font-size: 12px;
        padding-top: 20px;
        color: #e6e6e6;
      }
      .bankName {
        font-size: 20px;
        color: #e6e6e6;
      }
    }
    .status1 {
      position: absolute;
      top: 120px;
      right: -55px;
      text-align: right;
      font-size: 30px;
      transform: rotate(270deg);
      -ms-transform: rotate(270deg); /* IE 9 */
      -moz-transform: rotate(270deg); /* Firefox */
      -webkit-transform: rotate(270deg); /* Safari 和 Chrome */
      -o-transform: rotate(270deg);
      opacity: 0.4;
    }
    .status2 {
      position: absolute;
      top: 130px;
      right: -65px;
      text-align: right;
      font-size: 30px;
      transform: rotate(270deg);
      -ms-transform: rotate(270deg); /* IE 9 */
      -moz-transform: rotate(270deg); /* Firefox */
      -webkit-transform: rotate(270deg); /* Safari 和 Chrome */
      -o-transform: rotate(270deg);
      color: #808080;
      opacity: 0.4;
    }
    .status3 {
      opacity: 0.4;
      position: absolute;
      bottom: 40px;
      right: -10px;
      text-align: right;
      font-size: 30px;
      transform: rotate(270deg);
      -ms-transform: rotate(270deg); /* IE 9 */
      -moz-transform: rotate(270deg); /* Firefox */
      -webkit-transform: rotate(270deg); /* Safari 和 Chrome */
      -o-transform: rotate(270deg);
    }
    .status4 {
      opacity: 0.4;
      position: absolute;
      top: 120px;
      right: -55px;
      text-align: right;
      font-size: 30px;
      transform: rotate(270deg);
      -ms-transform: rotate(270deg); /* IE 9 */
      -moz-transform: rotate(270deg); /* Firefox */
      -webkit-transform: rotate(270deg); /* Safari 和 Chrome */
      -o-transform: rotate(270deg);
    }
  }
  .signOut-icon1 {
    color: #fff;
    font-size: 40px;
    cursor: pointer;
    text-align: right;
    padding-right: 10px;
    line-height: 30px;
    position: absolute;
    top: 0;
    right: 0;
    font-size: 40px;
    cursor: pointer;
  }
  .signOut-icon {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 40px;
    cursor: pointer;
    color: $mainColor;
    width: 40px;
    height: 30px;
    text-align: right;
    background-color: $background1;
    border-top: 1px solid $borderColor1;
    border-left: 1px solid $borderColor1;
    border-right: 1px solid $borderColor1;
    z-index: 99;
    padding-right: 10px;
  }
  .signOutHover {
    position: absolute;
    right: 0;
    top: 10px;
    height: 100px;
    width: 260px;
    z-index: 9;
    .list-button {
      position: absolute;
      right: 0;
      top: 20px;
      background-color: $background1;
      font-size: 20px;
      border: 1px solid $borderColor1;
      line-height: 30px;
      color: $mainColor;
      cursor: pointer;
      padding: 20px;
      .el-button {
        width: 100%;
        border: 0;
        background: transparent;
        padding: 0;
        font-size: 20px;
        line-height: 30px;
        color: $mainColor;
        cursor: pointer;
        display: block;
        text-align: left;
        padding: 0 5px;
      }
      .el-button + .el-button {
        margin: 0;
      }
      .el-button.type1 {
        color: #909399;
      }
    }
    .list-button-item {
      padding: 0 5px;
      text-decoration: underline;
    }
  }
}
</style>